import Image from 'next/image';
import { useMemo } from 'react';

// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
const keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

const triplet = (e1: number, e2: number, e3: number) =>
    keyStr.charAt(e1 >> 2) +
    keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
    keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
    keyStr.charAt(e3 & 63);

const rgbDataURL = (r: number, g: number, b: number) =>
    `data:image/gif;base64,R0lGODlhAQABAPAA${
        triplet(0, r, g) + triplet(b, 255, 255)
    }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

export type IDisplayImage = {
    url?: string;
    alt?: string;
    fill?: boolean;
    width?: number;
    height?: number;
    priority?: boolean;
    sizes?: string;
    className?: string;
    style?: Record<string, unknown>;
};

const DisplayImage = ({
    url,
    fill,
    width,
    height,
    alt,
    priority,
    sizes,
    className,
    style,
}: IDisplayImage) => {
    const elStyle = useMemo(
        () => ({
            ...style,
        }),
        [style]
    );

    return (
        <Image
            alt={alt ? alt : ''}
            src={url ? url : '/assets/placeholder.png'}
            // placeholder="blur"
            // blurDataURL={rgbDataURL(255, 255, 255)}
            width={width}
            height={height}
            fill={fill}
            priority={priority}
            sizes={sizes}
            className={className}
            style={elStyle}
        />
    );
};

export default DisplayImage;
